import React, { useEffect, useState } from "react";
import { PiEngineFill } from "react-icons/pi";
import { GrConnect } from "react-icons/gr";
import { GiSpring } from "react-icons/gi";
import { FaDrumSteelpan } from "react-icons/fa6";
import { GiTyre } from "react-icons/gi";
import { FaTachometerAlt } from "react-icons/fa";
import { FaCar } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { GoChecklist } from "react-icons/go";
import { MdOutlineCarCrash } from "react-icons/md";
import { GiCarKey } from "react-icons/gi";
import { BsBank } from "react-icons/bs";
import { FaBook } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import ProgressBar from "react-bootstrap";
import { BsFillLightningFill } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { IoSettingsOutline } from "react-icons/io5";
import { IoMdPerson } from "react-icons/io";
import { LiaImageSolid } from "react-icons/lia";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom/dist";
import { moment } from "moment";
import { useSelector } from "react-redux";
import "react-circular-progressbar/dist/styles.css";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import RadialSeparators from "./RadialSeparators";
import { PiEngine } from "react-icons/pi";
import { GiGearStick } from "react-icons/gi";


const ThreeWheelerReport = () => {
  const params = useParams();
  const navigate = useNavigate()
  const { organisationLogo } = useSelector((store) => store.orgLogo);
  const { brandLogo } = useSelector((store) => store.brandLogo);
  const [caseinfo, setcaseinfo] = useState("");
  const [casePerfomanceinfo, setcasePerfomanceinfo] = useState("");
  const [caseEngine, setCaseEngine] = useState("");
  const [caseTransmission, setCaseTransmission] = useState("");
  const [caseSuspension, setCaseSuspension] = useState("");
  const [caseBrakes, setCaseBrakes] = useState("");
  const [caseTyre, setCaseTyre] = useState("");
  const [caseInterior, setCaseInterior] = useState("");
  const [caseExterior, setCaseExterior] = useState("");
  const [caseRating, setCaseRating] = useState("");
  const [temploading, setTempLoading] = useState(false);
  const [percentage, setPercentage] = useState(66);

  //   console.log(casePerfomanceinfo,"1");
  console.log(casePerfomanceinfo, "casePerfomanceinfo");

  const roundImageArray = [
    {
      label: "Poor",
      percentage: 2,
      roundImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/leg02.png",
    },
    {
      label: "Average",
      percentage: 4,
      roundImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/leg04.png",
    },
    {
      label: "Satisfactory",
      percentage: 5,
      roundImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/leg06.png",
    },
    {
      label: "Good",
      percentage: 7,
      roundImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/ovleg07.png",
    },
    {
      label: "Excellent",
      percentage: 9,
      roundImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/leg09.png",
    },
  ];
  const selectImageArray = [
    {
      label: "Poor",
      lineImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png",
    },
    {
      label: "Average",
      lineImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png",
    },
    {
      label: "Satisfactory",
      lineImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png",
    },
    {
      label: "Good",
      lineImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png",
    },
    {
      label: "Excellent",
      lineImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png",
    },
  ];

  useEffect(() => {
    const caseId = params.caseId;
    const fetchData = async () => {
      try {
        const url = `${Baseurl}/api/v1/cases/individualcase/${caseId}`;
        const caseFetch = await axios.get(url);

        try {
          if (caseFetch.data.success) {
            const casedetails = caseFetch.data.case;

            setcaseinfo(casedetails);
            const vehicleType = casedetails.assetType;
            const carCasDetails = casedetails.threeWheeler;

            let findEngineCon = "";
            let findTransmissionCon = "";
            let findSuspensionCon = "";
            let findBrakesCon = "";
            let findTyreCon = "";
            let findElectricalCon = "";
            let findExteriorCon = "";
            let finalBreakCondition = "";
            let finalTyreCondition = "";

            let transmission = carCasDetails.transmissionCondition;
            if (vehicleType === "Three Wheeler") {
              findEngineCon = roundImageArray.find(
                (data) =>
                  data.label === carCasDetails.engineCondition
              );

              findTransmissionCon = roundImageArray.find(
                (data) => data.label === carCasDetails.transmissionCondition
              );
              findElectricalCon = roundImageArray.find(
                (data) =>
                  data.label === carCasDetails.electricalCondition
              );
              findExteriorCon = roundImageArray.find(
                (data) =>
                  data.label === carCasDetails.overallBodyCondition
              );
              findSuspensionCon = roundImageArray.find(
                (data) =>
                  data.label === carCasDetails.suspensionCondition
              );
              const findRareBrakesCon = roundImageArray.find(
                (data) =>
                  data.label === carCasDetails.frontBrakeCondition
              );
              const findFrontBrakesCon = roundImageArray.find(
                (data) =>
                  data.label === carCasDetails.rearBrakeCondition
              );


              const TotalTyreCon =
                Number(carCasDetails.lhRear) +
                Number(carCasDetails.rhRear) +
                Number(carCasDetails.front) +
                Number(carCasDetails.spare)


              findTyreCon = Number(TotalTyreCon) / 30;
              findTyreCon = Math.round(findTyreCon);

              if (findTyreCon === 1 || findTyreCon === 2) {
                finalTyreCondition = 2;
              } else if (findTyreCon === 3 || findTyreCon === 4) {
                finalTyreCondition = 4;
              } else if (findTyreCon === 5 || findTyreCon === 6) {
                finalTyreCondition = 5;
              } else if (findTyreCon === 7 || findTyreCon === 8) {
                finalTyreCondition = 7;
              } else if (findTyreCon === 9 || findTyreCon === 10) {
                finalTyreCondition = 9;
              }

              const totalBrackcon =
                Number(findFrontBrakesCon.percentage) +
                Number(findRareBrakesCon.percentage);
              findBrakesCon = Number(totalBrackcon) / 2;
              findBrakesCon = Math.round(findBrakesCon);


              if (findBrakesCon === 1 || findBrakesCon === 2) {
                finalBreakCondition = 2;
              } else if (findBrakesCon === 3 || findBrakesCon === 4) {
                finalBreakCondition = 4;
              } else if (findBrakesCon === 5 || findBrakesCon === 6) {
                finalBreakCondition = 5;
              } else if (findBrakesCon === 7 || findBrakesCon === 8) {
                finalBreakCondition = 7;
              } else if (findBrakesCon === 9 || findBrakesCon === 10) {
                finalBreakCondition = 9;
              }

              const findBreakCondition = roundImageArray.find(
                (data) => data.percentage === finalBreakCondition
              );
              const findTyreCondition = roundImageArray.find(
                (data) => data.percentage === finalTyreCondition
              );

              const overalRating =
                Number(findEngineCon.percentage) * Number(3) +
                Number(findTransmissionCon.percentage) * Number(1) +
                Number(findSuspensionCon.percentage) * Number(1) +
                Number(findElectricalCon.percentage) * Number(1) +
                Number(findExteriorCon.percentage) * Number(1) +
                Number(finalBreakCondition) * Number(1) +
                Number(finalTyreCondition) * Number(1);


              const overalavg = Number(overalRating) / 10;
              setcasePerfomanceinfo({
                findEngineCon: findEngineCon,
                findTransmissionCon: findTransmissionCon,
                findSuspensionCon: findSuspensionCon,
                findBrakesCon: findBreakCondition,
                findTyreCon: findTyreCondition,
                findElectricalCon: findElectricalCon,
                findExteriorCon: findExteriorCon,
                veralrating: overalavg.toFixed(2),
              });
            } else {
            }
          }
        } catch (error) { }
      } catch (error) { }
    };
    fetchData();
  }, [params.caseId]);

  console.log(caseinfo, "caseinfo");
  console.log(casePerfomanceinfo.veralrating, "casePerfomanceinfo");


  return (
    <>
      {caseinfo ? (
        <>
          <div>
            <div
              className="content"
              style={{
                border: "1px solid #686868",
                padding: "0 10px",
                width: "100%",
                position: "relative",
              }}
            >
              {/* A4 size */}
              <div className="row noprint p-3">
                <div className="col-sm-6">
                  <a
                    href=""
                    className="btn btn-success"
                    onclick="window.print();"
                  >
                    Print
                  </a>
                </div>
                <div className="col-sm-6 text-right">
                  <a href="" className="btn btn-success">
                    Cancel
                  </a>
                </div>
              </div>
              <div
                className="row"
                style={{
                  padding: "0 10px",
                  fontSize: "12pt",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="col-sm-6">
                  Ref. No / Loan No&nbsp;:&nbsp;{caseinfo.loanAgreementNo}
                </div>
                {caseinfo.caseId > 0 && caseinfo.caseId < 10 ? <> <div className="col-sm-6 text-right">
                  Case ID&nbsp;:&nbsp;0{caseinfo.caseId}
                </div></> : <> <div className="col-sm-6 text-right">
                  Case ID&nbsp;:&nbsp;{caseinfo.caseId}
                </div></>}

              </div>
              <div
                style={{
                  padding: "2px 15px",
                  border: "1px solid #000",
                  width: "100%",
                  height: "auto"
                }}
              >
                <div className="row">
                  <div className="col-sm-3 p-2">
                    <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/toplogo.png" />
                  </div>
                  <div className="col-sm-6 text-center">
                    <br />
                    <h4>
                      <strong>
                        Valuation Report
                        {/* <br /> */}
                        <p> {caseinfo.assetType}</p>
                        {/* <br /> */}
                        <p><strong>{`${caseinfo.brand} ${caseinfo.model} ${caseinfo.variant}`}</strong></p>
                      </strong>
                    </h4>
                    {/* <br /> */}
                    <p> &nbsp;&nbsp;</p>
                  </div>
                  <div className="col-sm-3 text-right">
                    <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/sidelogo.png" />
                  </div>
                </div>
              </div>
              <div style={{ padding: "0px 10px", height: "auto" }}>
                <div className="row">
                  <div className="col-sm-7">
                    {/* ==== main col 7*/}
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          marginTop: 2,
                          border: "1px solid #000",
                          padding: "3px 5px",
                        }}
                      >
                        <b style={{ color: "black" }}>      City : {caseinfo.city}</b>
                      </div>
                    </div>
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          marginTop: 2,
                          border: "1px solid #000",
                          padding: "3px 5px",
                        }}
                      >
                        <b style={{ color: "black" }}>   Color : {caseinfo.vehicleColor}</b>
                      </div>
                    </div>
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          marginTop: 2,
                          border: "1px solid #000",
                          padding: "3px 5px",
                        }}
                      >
                        <b style={{ color: "black" }}>   Odometer in Kms : {caseinfo.meterReading}</b>
                      </div>
                    </div>
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          marginTop: 2,
                          border: "1px solid #000",
                          padding: "3px 5px",
                        }}
                      >
                        <b style={{ color: "black" }}>   Fuel Type : {caseinfo.fueltype} </b>
                      </div>
                    </div>
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          marginTop: 2,
                          border: "1px solid #000",
                          padding: "3px 5px",
                        }}
                      >
                        <b style={{ color: "black" }}>   Transmission :{" "}
                          {caseinfo.threeWheeler.transmissionType} </b>
                      </div>
                    </div>
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          marginTop: 2,
                          border: "1px solid #000",
                          padding: "3px 5px",
                        }}
                      >
                        <b style={{ color: "black" }}> Accidental status : {caseinfo.threeWheeler.accidentalStatus}</b>
                      </div>
                    </div>
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          marginTop: 5,
                          padding: "3px 5px",
                          backgroundColor: "#d6d6d6",
                        }}
                      >
                        <span style={{ color: "#000", fontWeight: "bold" }}>
                          <b style={{ color: "black" }}>   Ex. Showroom Price&nbsp;:{caseinfo.marketPrice}</b>
                        </span>
                      </div>
                    </div>
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          marginTop: 5,
                          padding: "3px 5px",
                          backgroundColor: "#d6d6d6",
                        }}
                      >
                        <span style={{ color: "#000", fontWeight: "bold" }}>
                          <b style={{ color: "black" }}>   Estimated Market Value :{caseinfo.valuation}</b>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* ==== main col 7 end */}
                  <div className="col-sm-5 text-center imfix">
                    <img
                      src={caseinfo.images.frontImage}
                      className="fixed-img"
                    />
                    <br />
                  </div>
                </div>
              </div>
              {/* ====================================================================== */}
              <div className="row" style={{ padding: "2px 1px", }}>
                <div
                  className="col-sm-12 newReport"
                  style={{ border: "1px solid #000", borderRadius: 2 }}
                >
                  <table width="100%" style={{ padding: "5px 5px" }}>
                    <tbody>
                      <tr className="text-center" style={{ height: "20px" }}>
                        <td width="12%">
                          <div className="center-round">

                            <div className="legcontainer">
                              <img
                                src={casePerfomanceinfo.findEngineCon.roundImage}
                                alt="Small Image"
                                className="small-image"
                              />
                              <img
                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/engine.png"
                                className="image1"
                                alt="Image 1"
                              />
                            </div>
                          </div>
                        </td>
                        <td width="12%">
                          <div className="center-round">
                            <div className="legcontainer">
                              <img
                                src={
                                  casePerfomanceinfo.findTransmissionCon
                                    .roundImage
                                }
                                alt="Small Image"
                                className="small-image"
                              />
                              <img
                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/transm.png"
                                className="image1"
                                alt="Image 1"
                              />
                            </div>
                          </div>
                        </td>
                        <td width="12%">
                          <div className="center-round">
                            <div className="legcontainer">
                              <img
                                src={
                                  casePerfomanceinfo.findSuspensionCon.roundImage
                                }
                                alt="Small Image"
                                className="small-image"
                              />
                              <img
                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/Suspension.png"
                                className="image1"
                                alt="Image 1"
                              />
                            </div>
                          </div>
                        </td>
                        <td width="12%">
                          <div className="center-round">
                            <div className="legcontainer">
                              <img
                                src={casePerfomanceinfo.findBrakesCon.roundImage}
                                alt="Small Image"
                                className="small-image"
                              />
                              <img
                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/Brakes.png"
                                className="image1"
                                alt="Image 1"
                              />
                            </div>
                          </div>
                        </td>
                        <td width="12%">
                          <div className="center-round">
                            <div className="legcontainer">
                              <img
                                src={casePerfomanceinfo.findTyreCon.roundImage}
                                alt="Small Image"
                                className="small-image"
                              />
                              <img
                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/tyres.png"
                                className="image1"
                                alt="Image 1"
                              />
                            </div>
                          </div>
                        </td>
                        <td width="12%">
                          <div className="center-round">
                            <div className="legcontainer">
                              <img
                                src={casePerfomanceinfo.findElectricalCon.roundImage}
                                alt="Small Image"
                                className="small-image"
                              />
                              <img
                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/interior.png"
                                className="image1"
                                alt="Image 1"
                              />
                            </div>
                          </div>
                        </td>
                        <td width="12%">
                          <div className="center-round">
                            <div className="legcontainer">
                              <img
                                src={casePerfomanceinfo.findExteriorCon.roundImage}
                                alt="Small Image"
                                className="small-image"
                              />
                              <img
                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/Exterior.png"
                                className="image1"
                                alt="Image 1"
                              />
                            </div>
                          </div>
                        </td>
                        <td width="12%">
                          {casePerfomanceinfo.veralrating > '0.01' &&
                            casePerfomanceinfo.veralrating < '2' ? (
                            <>
                              <div className="center-round">
                                <div className="legcontainer">
                                  <img
                                    //   src="/assets/Adsum Advisory Services Pvt. Ltd._files/ovleg07.png "
                                    src="/assets/Adsum Advisory Services Pvt. Ltd._files/leg02.png"
                                    alt="Small Image"
                                    className="small-image"
                                  />
                                  <h1
                                    className="image1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {casePerfomanceinfo.veralrating}
                                  </h1>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {casePerfomanceinfo.veralrating > 2.01 &&
                                casePerfomanceinfo.veralrating < 4 ? (
                                <>
                                  <div className="center-round">
                                    <div className="legcontainer">
                                      <img
                                        //   src="/assets/Adsum Advisory Services Pvt. Ltd._files/ovleg07.png "
                                        src="/assets/Adsum Advisory Services Pvt. Ltd._files/leg04.png"
                                        alt="Small Image"
                                        className="small-image"
                                      />
                                      <h1
                                        className="image1"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        {casePerfomanceinfo.veralrating}
                                      </h1>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {casePerfomanceinfo.veralrating > 4.01 &&
                                    casePerfomanceinfo.veralrating < 6 ? (
                                    <>
                                      <div className="center-round">
                                        <div className="legcontainer">
                                          <img
                                            //   src="/assets/Adsum Advisory Services Pvt. Ltd._files/ovleg07.png "
                                            src="/assets/Adsum Advisory Services Pvt. Ltd._files/leg06.png"
                                            alt="Small Image"
                                            className="small-image"
                                          />
                                          <div>
                                            <h1
                                              className="image1"
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              {casePerfomanceinfo.veralrating}
                                            </h1>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {casePerfomanceinfo.veralrating > 6.01 &&
                                        casePerfomanceinfo.veralrating < 8 ? (
                                        <>
                                          <div className="center-round">
                                            <div className="legcontainer">
                                              <img
                                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/ovleg07.png "
                                                // src="/assets/Adsum Advisory Services Pvt. Ltd._files/leg07.png"
                                                alt="Small Image"
                                                className="small-image"
                                              />
                                              <h1
                                                className="image1"
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                {casePerfomanceinfo.veralrating}
                                              </h1>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="center-round">
                                            <div className="legcontainer">
                                              <img
                                                //   src="/assets/Adsum Advisory Services Pvt. Ltd._files/ovleg07.png "
                                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/leg09.png"
                                                alt="Small Image"
                                                className="small-image"
                                              />
                                              <h1
                                                className="image1"
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                {casePerfomanceinfo.veralrating}
                                              </h1>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                      <tr className="legtext" style={{ height: "20px" }}>
                        <td>
                          Engine
                          {/* <br /> */}
                          <p>{casePerfomanceinfo.findEngineCon.percentage}/10</p>
                        </td>
                        <td>
                          Transmission
                          {/* <br /> */}
                          <p> {casePerfomanceinfo.findTransmissionCon.percentage}/10</p>
                        </td>
                        <td>
                          Suspension
                          {/* <br /> */}
                          <p> {casePerfomanceinfo.findSuspensionCon.percentage}/10</p>
                        </td>
                        <td>
                          Brakes
                          {/* <br /> */}
                          <p>{casePerfomanceinfo.findBrakesCon.percentage}/10</p>
                        </td>
                        <td>
                          Tyres
                          {/* <br /> */}
                          <p>{casePerfomanceinfo.findTyreCon.percentage}/10</p>
                        </td>
                        <td>
                          Interior
                          {/* <br /> */}
                          <p> {casePerfomanceinfo.findElectricalCon.percentage}/10</p>
                        </td>
                        <td>
                          Exterior
                          {/* <br /> */}
                          <p>{casePerfomanceinfo.findElectricalCon.percentage}/10</p>
                        </td>
                        <td>
                          <strong>
                            OVERALL
                            {/* <br /> */}
                            <p> {casePerfomanceinfo.veralrating} of 10</p>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* ====================================================================== */}
              <div className="row">
                <div className="col-sm-12 newReport">
                  <table
                    width="100%"
                    border={1}
                    style={{ fontSize: "10pt", border: "1px solid #688", height: "auto" }}
                  >
                    <tbody>
                      <tr className="p-3" style={{ height: "30px" }}>
                        <td width="75%" rowSpan={2}>
                          &nbsp;&nbsp;&nbsp;&nbsp;Inspection Address
                          {/* <br /> */}
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;{caseinfo.vehicleLocation}</p>
                        </td>
                        <td className="text-center">
                          Created Date
                          {/* <br /> */}
                          <p> {caseinfo.createdAt.slice(0, 10)}</p>
                        </td>
                      </tr>
                      <tr style={{ height: "30px" }}>
                        <td className="text-center">
                          Service Date
                          {/* <br /> */}
                          <p > {caseinfo.submittedAt.slice(0, 10)}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div style={{ padding: 8 }} /> */}
              <table>
                <tbody>
                  <tr style={{ height: "20px" }}>
                    <td>
                      <div className="imgcontainer">
                        <img
                          src="/assets/Adsum Advisory Services Pvt. Ltd._files/car01.jpg"
                          className="image3"
                        />
                      </div>
                    </td>
                    <td>
                      <strong>&nbsp;&nbsp;VEHICLE DETAILS</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="row">
                <div className="col-sm-12 newReport">
                  {/* *=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*= */}
                  <table width="100%">
                    <tbody>
                      <tr style={{ verticalAlign: "top", height: "30px" }}>
                        <td width="24%">
                          <div className="col-sm-12 newReport column-ht" style={{ paddingTop: '0px', height: '100%' }}>
                            <img
                              src="/assets/Adsum Advisory Services Pvt. Ltd._files/car08.jpg"
                              width="auto"
                              height="10%"
                            />
                            {/* <br /> */}
                            <p style={{ fontSize: '12px' }}>
                              Registration
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '13px' }}><strong>{caseinfo.regdNo}</strong></p>
                            <hr />
                            <p style={{ fontSize: '12px' }}>
                              Chasis Number
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '13px' }}> <strong>
                              {caseinfo.chasisNo}
                              {/* <br /> */}
                            </strong></p>
                            <hr />
                            <p style={{ fontSize: '12px' }}>
                              Engine Number
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '13px' }}> <strong>
                              {caseinfo.engNo}
                            </strong></p>
                            <hr />
                          </div>
                        </td>
                        <td width="24%">
                          <div className="col-sm-12 newReport column-ht" style={{ paddingTop: '0px', height: '100%' }}>
                            <img
                              src="/assets/Adsum Advisory Services Pvt. Ltd._files/car02.jpg"
                              width="auto"
                              height="10%"
                            />
                            <p style={{ fontSize: '12px' }}>
                              Body Type
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '13px' }}> <strong>{caseinfo.bodyType}</strong></p>
                            <hr />
                            <p style={{ fontSize: '13px' }}>
                              Manufacture Year
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '13px' }}><strong>{caseinfo.manufacturingDate}</strong></p>
                            <p style={{ fontSize: '12px' }}>
                              Date of Registration
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '13px' }}> <strong>{caseinfo.regdDate}</strong></p>
                            <hr />
                            <p style={{ fontSize: '12px' }}>
                              Vehicle Type
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '13px' }}> <strong>{caseinfo.assetType}</strong></p>
                          </div>
                        </td>
                        <td width="25%">
                          <div className="col-sm-12 newReport column-ht" style={{ paddingTop: '0px', height: '100%' }}>
                            <img
                              src="/assets/Adsum Advisory Services Pvt. Ltd._files/car03.jpg"
                              width="auto"
                              height="10%"
                            />
                            <p style={{ fontSize: '13px' }}>
                              Owner Name
                            </p>
                            <p style={{ fontSize: '13px', fontWeight: '500', color: '#000' }}>
                              {caseinfo.regdOwner}
                            </p> <hr />
                            <p style={{ fontSize: '12px' }}>
                              Owner Serial No
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '13px' }}> <strong> {caseinfo.ownerSerialNo}</strong></p>
                            <hr />
                            <p style={{ fontSize: '12px' }}>
                              Proposed Owner Name
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '13px' }}><strong>{caseinfo.name}</strong></p>
                            <hr />
                            {/* <br /> */}
                          </div>
                        </td>
                        <td width="25%">
                          <div className="col-sm-12 newReport column-ht" style={{ paddingTop: '0px', height: '100%' }}>
                            <img
                              src="/assets/Adsum Advisory Services Pvt. Ltd._files/car04.jpg"
                              width="auto"
                              height="10%"
                            />
                            <p style={{ fontSize: '12px' }}>
                              Report Requested by
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '13px', fontWeight: '500', color: '#000' }}>
                              {caseinfo.requesterName}
                            </p>
                            <hr />
                            <p style={{ fontSize: '12px' }}>
                              Case Type
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '13px' }}> <strong>{caseinfo.caseType}</strong></p>
                            <hr />
                            <p style={{ fontSize: '12px' }}>
                              Purpose Of Valuation
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '13px' }}> <strong>{caseinfo.valuationPurpose}</strong></p>
                            <hr />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* *=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*= */}
                  <div style={{ padding: 2 }} />
                  <table border={1} width="100%">
                    <tbody>
                      <tr
                        className="text-center"
                        style={{ border: "1px solid black", height: "30px" }}
                      >
                        <td
                          width="50%"
                          style={{ borderRight: "1px solid #686868" }}
                        >
                          Insurance Company
                          {/* <br /> */}
                          <p><strong>{caseinfo.insuranceCompany}</strong></p>
                        </td>
                        <td width="25%">
                          Insurance Status
                          {/* <br /> */}
                          <div>
                            {caseinfo.insuranceStatus === "Yes" ? (
                              <strong>Expired</strong>
                            ) : (
                              <strong>Running</strong>
                            )}
                          </div>
                        </td>
                        <td style={{ borderLeft: "1px solid #686868" }}>
                          Insurance Valid Till
                          {/* <br /> */}
                          <p> <strong>
                            {caseinfo.vehicleEdetails.vehicleInsuranceUpto}
                          </strong></p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* <div style={{ padding: 5 }} /> */}
                  <table width="100%">
                    <tbody>
                      <tr style={{ height: "30px" }}>
                        <td width="65%" style={{ border: "1px solid #686868" }}>
                          <p> &nbsp;&nbsp;Remarks&nbsp;:&nbsp;{caseinfo.yourRemark}</p>
                          <p> &nbsp;&nbsp;Extrior Remarks&nbsp;:&nbsp;{caseinfo.extriorRemark}</p>
                          <a className="ps-2" target="_blank" href={caseinfo.videos} style={{ color: 'blue', textDecoration: 'underline' }}>
                            Preview Video Clip
                          </a>
                          &nbsp;
                          <a style={{ color: 'blue', textDecoration: 'underline' }} target="_blank"
                            href={caseinfo.imageUrl}
                          >
                            Preview Images
                          </a>
                        </td>
                        <td
                          width="35%"
                          rowSpan={2}
                          style={{ border: "1px solid black" }}
                        >
                          <div className="text-center">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src="/assets/stamp.jpeg"
                                width="40px"
                                height="40px"
                              />
                            </div>
                            <div>Authorized Signatory</div>
                            {/* <br /> */}
                          </div>
                        </td>
                      </tr>
                      <tr style={{ height: "20px" }}>
                        <td width="65%" style={{ border: "1px solid #686868" }}>
                          &nbsp;&nbsp;Field Service By:&nbsp;
                          {caseinfo.fieldServiceBy}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* page footer ends */}
              {/* <div style={{ padding: 5 }} /> */}
            </div>

            <div className="page-break" />
            <div
              className="content"
              style={{
                margin: "30px 0 0 0",
                padding: "0 10px",
                width: "100%",
                border: "1px solid #000",
                height: "auto"
              }}
            >
              <div style={{ marginTop: 5 }}></div>
              {/*-  header */}
              <div className="row">
                <div className="col-sm-12 newReport">

                  <table
                    className="rcrowheight rtborder"
                    style={{ borderCollapse: "collapse" }}
                    width="100%"
                  >
                    <tbody>
                      <tr style={{ height: "10px" }}>
                        <td>
                          <strong>REGISTRATION DETAILS</strong>
                        </td>
                        <td>
                          <strong>AS PER VAHAN</strong>
                        </td>
                        <td>
                          <strong>AS PER RC</strong>
                        </td>
                        <td>
                          <strong>AS PER ACTUAL</strong>
                        </td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Vehicle Registration No</td>
                        <td>{caseinfo.vehicleEdetails.regNo}</td>
                        <td>{caseinfo.vehicleEdetails.regNo}</td>
                        <td>{caseinfo.regdNo}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%" style={{ fontSize: '10px', fontWeight: '800' }}>Vehicle Mfg Name </td>
                        <td>{caseinfo.vehicleEdetails.vehicleManufacturerName}</td>
                        <td>{caseinfo.vehicleEdetails.vehicleManufacturerName}</td>
                        <td>{caseinfo.vehicleEdetails.vehicleManufacturerName}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%" style={{ fontSize: '10px', fontWeight: '800' }}>Vehicle Model </td>
                        <td>{caseinfo.vehicleEdetails.model}</td>
                        <td>{caseinfo.vehicleEdetails.model}</td>
                        <td>{caseinfo.vehicleEdetails.model}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Chasis No&nbsp;:&nbsp;</td>
                        <td>{caseinfo.vehicleEdetails.chassis}</td>
                        <td>{caseinfo.vehicleEdetails.chassis}</td>
                        <td>{caseinfo.chasisNo}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Engine No&nbsp;:&nbsp;</td>
                        <td> {caseinfo.vehicleEdetails.engine}</td>
                        <td>{caseinfo.vehicleEdetails.engine}</td>
                        <td>{caseinfo.engNo}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Vehicle Color</td>
                        <td>{caseinfo.vehicleEdetails.vehicleColour}</td>
                        <td>{caseinfo.vehicleEdetails.vehicleColour}</td>
                        <td>{caseinfo.vehicleColor}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Fuel Type&nbsp;:&nbsp;</td>
                        <td>{caseinfo.vehicleEdetails.type}</td>
                        <td>{caseinfo.vehicleEdetails.type}</td>
                        <td>{caseinfo.fueltype}</td>
                      </tr>

                      <tr style={{ height: "10px" }}>
                        <td width="35%">Owner Serial No</td>
                        <td>{caseinfo.vehicleEdetails.ownerCount}</td>
                        <td>{caseinfo.vehicleEdetails.ownerCount}</td>
                        <td>{caseinfo.ownerSerialNo}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Reg Owner Name</td>
                        <td style={{ fontSize: '12px' }}>{caseinfo.vehicleEdetails.owner}</td>
                        <td style={{ fontSize: '12px' }}>{caseinfo.vehicleEdetails.owner}</td>
                        <td style={{ fontSize: '12px' }}>{caseinfo.regdOwner}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Date Of Registration</td>
                        <td> {caseinfo.vehicleEdetails.regDate}</td>
                        <td> {caseinfo.vehicleEdetails.regDate}</td>
                        <td>{caseinfo.regdDate}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">RTA Name</td>
                        <td style={{ fontSize: '12px' }}> {caseinfo.vehicleEdetails.regAuthority}</td>
                        <td style={{ fontSize: '12px' }}> {caseinfo.vehicleEdetails.regAuthority}</td>
                        <td>-</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Year Of Manufacture</td>
                        <td>{caseinfo.vehicleEdetails.vehicleManufacturingMonthYear}</td>
                        <td>{caseinfo.vehicleEdetails.vehicleManufacturingMonthYear}</td>
                        <td>{caseinfo.manufacturingDate}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Fitness Expiry Date</td>
                        <td>{caseinfo.vehicleEdetails.rcExpiryDate}</td>
                        <td>{caseinfo.vehicleEdetails.rcExpiryDate}</td>
                        <td>{caseinfo.threeWheeler.fitnessExpiry}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Insurance Exp Date</td>
                        <td>{caseinfo.vehicleEdetails.vehicleInsuranceUpto}</td>
                        <td>{caseinfo.vehicleEdetails.vehicleInsuranceUpto}</td>
                        <td>{caseinfo.otherDetails.insuranceExpiry}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Tax Valid Up To</td>
                        <td>{caseinfo.vehicleEdetails.vehicleTaxUpto}</td>
                        <td>{caseinfo.vehicleEdetails.vehicleTaxUpto}</td>
                        <td>{caseinfo.threeWheeler.roadTaxValidity}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Permit Up To</td>
                        <td>{caseinfo.vehicleEdetails.permitValidUpto}</td>
                        <td>{caseinfo.vehicleEdetails.permitValidUpto}</td>
                        <td>-</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Permit Type</td>
                        <td>{caseinfo.vehicleEdetails.permitType}</td>
                        <td>{caseinfo.vehicleEdetails.permitType}</td>
                        <td>-</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Hypothecation</td>
                        <td>{caseinfo.vehicleEdetails.rcFinancer}</td>
                        <td>{caseinfo.vehicleEdetails.rcFinancer}</td>
                        <td> {caseinfo.hypothecationBank}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Cubic Capacity</td>
                        <td>{caseinfo.vehicleEdetails.vehicleCubicCapacity}</td>
                        <td>{caseinfo.vehicleEdetails.vehicleCubicCapacity}</td>
                        <td>{caseinfo.cubicCapacity}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Gross Vehicle Weight</td>
                        <td>{caseinfo.vehicleEdetails.grossVehicleWeight}</td>
                        <td>{caseinfo.vehicleEdetails.grossVehicleWeight}</td>
                        <td>{caseinfo.vehicleWeight}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Seating Capacity</td>
                        <td> {caseinfo.vehicleEdetails.vehicleSeatCapacity}</td>
                        <td> {caseinfo.vehicleEdetails.vehicleSeatCapacity}</td>
                        <td>{caseinfo.seatingCapacity}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Number Plate</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>{caseinfo.numberPlate}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Status</td>
                        <td>{caseinfo.vehicleEdetails.status}</td>
                        <td>{caseinfo.vehicleEdetails.status}</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Blacklist Status</td>
                        <td>{caseinfo.vehicleEdetails.blacklistStatus}</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Is Commercial</td>
                        {caseinfo.assetType === "Commercial" ? (
                          <td>Yes</td>
                        ) : (
                          <td>no</td>
                        )}
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Challan Details</td>
                        <td>NA</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>

                  <h4 style={{ marginTop: "5px" }}>
                    <strong>

                      OTHERS:
                    </strong>
                  </h4>
                  <table className="rowheight" width="100%">
                    <tbody>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Document</td>
                        <td colSpan={2}> {caseinfo.documentVerification}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Asset Type</td>
                        <td colSpan={2}>{caseinfo.assetType}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Manufacturing Year</td>
                        <td colSpan={2}> {caseinfo.manufacturingDate}</td>
                      </tr>
                    </tbody>
                  </table>
                  <h4 style={{ marginTop: "5px" }}>
                    <strong>

                      DOCUMENT DETAILS:
                    </strong>
                  </h4>
                  <table className="rowheight" width="100%" height="auto">
                    <tbody>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Fitness Expiry Date</td>
                        <td colSpan={2}>
                          {" "}
                          {caseinfo.threeWheeler.fitnessExpiry}
                        </td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Insurance</td>
                        <td colSpan={2}>{caseinfo.insuranceCompany}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Road Tax Validity Upto</td>
                        <td colSpan={2}>
                          {" "}
                          {caseinfo.threeWheeler.roadTaxValidity}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div style={{ marginTop: 10 }}>&nbsp;</div> */}
            </div>
            {/* <!-- page 2 ends --> */}

            <div className="page-break" />
            <div
              className="content"
              style={{
                margin: "30px 0 0 0",
                padding: "0 10px",
                width: "100%",
                border: "1px solid #000",
                height: "auto"
              }}
            >
              {/*-  header */}
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  OTHER DETAILS:
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Fuel Type</td>
                    <td width="30%">{caseinfo.fueltype}</td>
                    <td width="30%"></td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Gross Vehicle Weight</td>
                    <td width="30%">{caseinfo.vehicleWeight}</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Odormeter Type</td>
                    <td width="30%">
                      {" "}
                      {caseinfo.threeWheeler.odometerType}
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Cubic Capacity</td>
                    <td width="30%">{caseinfo.cubicCapacity}</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Vehicle Color</td>
                    <td width="30%">{caseinfo.vehicleColor}</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Meter Reading In Km</td>
                    <td width="30%">{caseinfo.meterReading}</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Number Plate</td>
                    <td width="30%">{caseinfo.numberPlate}</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Seating Capacity</td>
                    <td width="30%">{caseinfo.seatingCapacity}</td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  ENGINE:
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Engine Condition</td>
                    <td width="30%">{caseinfo.threeWheeler.engineCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.engineCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.engineCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.engineCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.engineCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.engineCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Fuel Tank Condition</td>
                    <td width="30%">{caseinfo.threeWheeler.fuelTankConditiuon}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.fuelTankConditiuon === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.fuelTankConditiuon === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.fuelTankConditiuon === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.fuelTankConditiuon === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.fuelTankConditiuon === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Engine Shield</td>
                    <td width="30%">{caseinfo.threeWheeler.engineShield}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.engineShield === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.engineShield === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.engineShield === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.engineShield === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.engineShield === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  TRANSMISSION:
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Transmission Type</td>
                    <td width="30%">{caseinfo.threeWheeler.transmissionType}</td>
                    <td width="30%">
                      <span>&nbsp;</span>
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">                    Transmission Condition</td>
                    <td width="30%">{caseinfo.threeWheeler.transmissionCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.transmissionCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.transmissionCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.transmissionCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.transmissionCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.transmissionCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  SUSPENSION
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Suspension Condition</td>
                    <td width="30%">{caseinfo.threeWheeler.suspensionCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.suspensionCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.suspensionCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.suspensionCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.suspensionCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.suspensionCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  BRAKES
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Front Brake Condition</td>
                    <td width="30%">{caseinfo.threeWheeler.frontBrakeCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.rearBrakeCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearBrakeCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearBrakeCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearBrakeCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearBrakeCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>

                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Rear Brake Condition</td>
                    <td width="30%">{caseinfo.threeWheeler.rearBrakeCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.rearBrakeCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearBrakeCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearBrakeCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearBrakeCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearBrakeCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>

                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  TYRES & CONDITION
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Type</td>
                    <td width="30%">Tyres</td>
                    <td width="30%">Tyres</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Front</td>
                    <td width="30%">{caseinfo.threeWheeler.front}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.front >= '0' && caseinfo.threeWheeler.front <= 20 ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.front >= '20.01' && caseinfo.threeWheeler.front <= '40' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.front >= '40.01' && caseinfo.threeWheeler.front <= '60' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.front >= '60.01' && caseinfo.threeWheeler.front <= '80' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.front >= '80.01' && caseinfo.threeWheeler.front <= '100' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Lh Rear</td>
                    <td width="30%">{caseinfo.threeWheeler.lhRear}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.lhRear >= '0' && caseinfo.threeWheeler.lhRear <= 20 ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.lhRear >= '20.01' && caseinfo.threeWheeler.lhRear <= '40' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.lhRear >= '40.01' && caseinfo.threeWheeler.lhRear <= '60' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.lhRear >= '60.01' && caseinfo.threeWheeler.lhRear <= '80' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.lhRear >= '80.01' && caseinfo.threeWheeler.lhRear <= '100' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Rh Rear</td>
                    <td width="30%">{caseinfo.threeWheeler.rhRear}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.rhRear >= '0' && caseinfo.threeWheeler.rhRear <= 20 ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rhRear >= '20.01' && caseinfo.threeWheeler.rhRear <= '40' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rhRear >= '40.01' && caseinfo.threeWheeler.rhRear <= '60' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rhRear >= '60.01' && caseinfo.threeWheeler.rhRear <= '80' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rhRear >= '80.01' && caseinfo.threeWheeler.rhRear <= '100' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  {
                    caseinfo.threeWheeler.spareTyre > '0' ?
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Spare</td>
                        <td width="30%">{caseinfo.threeWheeler.spare}</td>
                        <td width="30%">
                          {
                            caseinfo.threeWheeler.spare >= '0' && caseinfo.threeWheeler.spare <= 20 ?
                              <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                          }
                          {
                            caseinfo.threeWheeler.spare >= '20.01' && caseinfo.threeWheeler.spare <= '40' ?
                              <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                          }
                          {
                            caseinfo.threeWheeler.spare >= '40.01' && caseinfo.threeWheeler.spare <= '60' ?
                              <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                          }
                          {
                            caseinfo.threeWheeler.spare >= '60.01' && caseinfo.threeWheeler.spare <= '80' ?
                              <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                          }
                          {
                            caseinfo.threeWheeler.spare >= '80.01' && caseinfo.threeWheeler.spare <= '100' ?
                              <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                          }
                        </td>
                      </tr> : <></>
                  }
                </tbody>
              </table>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Total Tyres Ex Spare</td>
                    <td width="30%">{caseinfo.threeWheeler.totalTyres}</td>
                    <td width="30%">-</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Available Tyres</td>
                    <td width="30%">{caseinfo.threeWheeler.availableTyre}</td>
                    <td width="30%">-</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Missing Tyres</td>
                    <td width="30%">{caseinfo.threeWheeler.missingTyre}</td>
                    <td width="30%">-</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Spare Tyre</td>
                    <td width="30%">{caseinfo.threeWheeler.spareTyre}</td>
                    <td width="30%">-</td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  VEHICLE INTERIOR
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Seats</td>
                    <td width="30%">{caseinfo.threeWheeler.seats}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.seats === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.seats === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.seats === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.seats === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.seats === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">InteriorTrims</td>
                    <td width="30%">{caseinfo.threeWheeler.interiorTrims}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.interiorTrims === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.interiorTrims === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.interiorTrims === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.interiorTrims === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.interiorTrims === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Inside Rear View Mirror</td>
                    <td width="30%">{caseinfo.threeWheeler.insideRearViewMirror}</td>
                    <td width="30%">
                      {caseinfo.threeWheeler.insideRearViewMirror === "Yes" ? (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/right.jpg" />
                      ) : (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/wrong.jpg" />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <div className="row">
                <div className="col-sm-12 newReport">
                  <div
                    style={{
                      padding: 2,
                      border: "1px solid #000",
                      width: "100%",
                    }}
                  >
                 
                    <div className="col-sm-12 newReport text-center">
                      <strong>Head Office:</strong>
                      <br />
                      J15-A, LANE NO-40, RAJAPURI,J15-UTTAM NAGAR,DELHI, 110059
                      <br />
                      Phone: 9866773092 | Email: pankaj623@gmail.com | Website:
                      https://www.adsum.co.in
                    </div>
                  </div>
                </div>
              
              </div>
              <div style={{ marginTop: 10 }}>&nbsp;</div> */}
            </div>
            {/* <!-- page 3 ends --> */}

            <div className="page-break" />
            <div
              className="content"
              style={{
                margin: "30px 0 0 0",
                padding: "0 10px",
                width: "100%",
                height: "auto",
                border: "2px solid #000",
              }}
            >
              {/*-  header */}
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  VEHICLE EXTERIOR
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Body Type</td>
                    <td width="30%">{caseinfo.threeWheeler.vehiclebodyType}</td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Front Mudguard</td>
                    <td width="30%">{caseinfo.threeWheeler.frontMudguard}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.frontMudguard === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.frontMudguard === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.frontMudguard === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.frontMudguard === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.frontMudguard === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Rear  Wheels Mudguard</td>
                    <td width="30%">{caseinfo.twoWheeler.vehicleExterior.bodyCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.rearWheelsMudguards === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearWheelsMudguards === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearWheelsMudguards === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearWheelsMudguards === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearWheelsMudguards === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Windscreen</td>
                    <td width="30%">{caseinfo.threeWheeler.windScreenCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.windScreenCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.windScreenCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.windScreenCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.windScreenCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.windScreenCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Windscreen Glass</td>
                    <td width="30%">{caseinfo.threeWheeler.windScreenGlassStatus}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.windScreenGlassStatus === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.windScreenGlassStatus === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.windScreenGlassStatus === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.windScreenGlassStatus === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.windScreenGlassStatus === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Side Doors</td>
                    <td width="30%">{caseinfo.threeWheeler.sideDoors}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.sideDoors === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.sideDoors === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.sideDoors === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.sideDoors === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.sideDoors === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Door Glasses</td>
                    <td width="30%">{caseinfo.threeWheeler.doorGlasses}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.doorGlasses === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.doorGlasses === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.doorGlasses === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.doorGlasses === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.doorGlasses === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Outside Rear View Mirrors</td>
                    <td width="30%">{caseinfo.threeWheeler.outsideRearViewMirror}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.outsideRearViewMirror === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.outsideRearViewMirror === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.outsideRearViewMirror === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.outsideRearViewMirror === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.outsideRearViewMirror === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Paint</td>
                    <td width="30%">{caseinfo.threeWheeler.paintStatus}</td>
                    <td width="30%">
                      -
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Paint Condition</td>
                    <td width="30%">{caseinfo.threeWheeler.paintCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.paintCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.paintCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.paintCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.paintCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.paintCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">OverallBody Condition</td>
                    <td width="30%">{caseinfo.threeWheeler.overallBodyCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.overallBodyCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.overallBodyCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.overallBodyCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.overallBodyCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.overallBodyCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  WHEELS
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Wheel Type</td>
                    <td width="30%">                    {caseinfo.threeWheeler.wheelsType}</td>
                    <td width="30%">---</td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  ELECTRICALS:
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Battery Condition</td>
                    <td width="30%">{caseinfo.threeWheeler.battery}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.battery === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.battery === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.battery === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.battery === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.battery === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">HeadLamp</td>
                    <td width="30%">{caseinfo.threeWheeler.headLamp}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.headLamp === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.headLamp === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.headLamp === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.headLamp === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.headLamp === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">TailLamp</td>
                    <td width="30%">{caseinfo.threeWheeler.tailLamp}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.tailLamp === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.tailLamp === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.tailLamp === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.tailLamp === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.tailLamp === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Front Indicators</td>
                    <td width="30%" >{caseinfo.threeWheeler.frontIndicators}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.frontIndicators === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.frontIndicators === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.frontIndicators === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.frontIndicators === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.frontIndicators === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Rear Indicators</td>
                    <td width="30%">{caseinfo.threeWheeler.rearIndicators}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.rearIndicators === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearIndicators === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearIndicators === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearIndicators === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.rearIndicators === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  ACCESSORIES (IF ANY):
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">ToolKit</td>
                    <td width="30%">{caseinfo.threeWheeler.toolKit}</td>
                    <td width="30%">
                      {caseinfo.threeWheeler.toolKit === "Yes" ? (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/right.jpg" />
                      ) : (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/wrong.jpg" />
                      )}
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Others (If Any)</td>
                    <td width="30%">{caseinfo.threeWheeler.othersAccessories}</td>
                    <td width="30%">
                      {caseinfo.threeWheeler.othersAccessories === "Yes" ? (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/right.jpg" />
                      ) : (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/wrong.jpg" />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  ADDITIONAL DETAILS:
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Whether Accidental</td>
                    <td width="30%">{caseinfo.threeWheeler.accidentalStatus}</td>
                    <td width="30%">
                      {caseinfo.threeWheeler.accidentalStatus === "Yes" ? (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/right.jpg" />
                      ) : (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/wrong.jpg" />
                      )}
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Level Of Maintenance</td>
                    <td width="30%">{caseinfo.threeWheeler.maintenanceLevel}</td>
                    <td width="30%">
                      {
                        caseinfo.threeWheeler.maintenanceLevel === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.maintenanceLevel === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.maintenanceLevel === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.maintenanceLevel === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.threeWheeler.maintenanceLevel === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "30px" }}>
                    <td width="35%">Other Repairs If Any</td>
                    <td width="30%">{caseinfo.threeWheeler.otherRepairs}</td>
                    <td width="30%">
                      {caseinfo.threeWheeler.otherRepairs === "Yes" ? (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/right.jpg" />
                      ) : (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/wrong.jpg" />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <div style={{ marginTop: 30 }}>&nbsp;</div> */}
              <div className="row">
                <div className="col-sm-12 newReport">
                  <div
                    style={{
                      padding: 2,
                      border: "1px solid #000",
                      width: "100%",
                      height: "auto"
                    }}
                  >
                    {/* page footer */}
                    <div className="col-sm-12 newReport text-center">
                      <strong>Head Office:</strong>
                      <br />
                      Door No. 411,4th Floor, Eden Amsri Square,beside Apollo Hospital, Sangeet Circle,
                      <br />
                      Secunderabad 500003, Telengana
                    </div>
                  </div>
                </div>
                {/* page footer ends */}
              </div>
              <div style={{ marginTop: 10 }}>&nbsp;</div>
            </div>
            {/* <!-- page 4 ends --> */}
            <div className="page-break" />
            <div style={{ marginTop: 10 }}>&nbsp;</div>
            <div
              style={{
                padding: "2px 25px",
                border: "1px solid #000",
                width: "100%",
                height: "auto"
              }}
            >
              <div className="row">
                <div className="col-sm-3 p-2">
                  <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/toplogo.png" />
                </div>
                <div className="col-sm-6 text-center">
                  <br />
                  <h4>
                    <strong>
                      Valuation Report
                      <br />
                      {caseinfo.assetType}
                      <br />
                      {`${caseinfo.brand} ${caseinfo.model} ${caseinfo.variant}`}
                    </strong>
                  </h4>
                  &nbsp;
                </div>
                <div className="col-sm-3 text-right">
                  <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/sidelogo.png" />
                </div>
              </div>
            </div>
            {/*-  header */}

            {/*- content border */}
            <h4>
              <strong>
                IMAGES:
              </strong>
            </h4>
            <div className="row">
              <div className="col-sm-12 newReport">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                  {
                    Object.keys(caseinfo.images).map((key) => {
                      if (key === 'selfieWithVehicle' && caseinfo.images[key] !== "") {
                        return (
                          <span key={key} style={{ width: '45%', margin: '10px' }}>
                            <a target="_blank" href={caseinfo.images.selfieWithVehicle}>
                              <div style={{ position: "relative" }}>
                                <img
                                  src={caseinfo.images.selfieWithVehicle}
                                  style={{ height: "250px", width: "100%" }}
                                  alt="Selfie with Vehicle"
                                />
                                <img
                                  src="/assets/Adsum Advisory Services Pvt. Ltd._files/sidelogo.png"
                                  className="small-logo"
                                  width="75px"
                                  height="53px"
                                  alt="Side Logo"
                                />
                              </div>
                            </a>
                          </span>
                        );
                      }
                      return null;
                    })
                  }
                  {
                    Object.keys(caseinfo.images).map((key) => {
                      if (key === 'frontImage' && caseinfo.images[key] !== "") {
                        return (
                          <span key={key} style={{ width: '45%', margin: '10px' }}>
                            <a key={key} target="_blank" href={caseinfo.images.frontImage}>
                              <div style={{ position: "relative" }}>
                                <img
                                  src={caseinfo.images.frontImage}
                                  style={{ height: "250px", width: "420px" }}
                                  alt="Front Image"
                                />
                                <img
                                  src="/assets/Adsum Advisory Services Pvt. Ltd._files/sidelogo.png"
                                  className="small-logo"
                                  width="75px"
                                  height="53px"
                                  alt="Side Logo"
                                />
                              </div>
                            </a>
                          </span>
                        );
                      }
                      return null;
                    })
                  }
                  {
                    Object.keys(caseinfo.images).map((key) => {
                      if (key !== 'selfieWithVehicle' && key !== 'frontImage' && caseinfo.images[key] !== "") {
                        return (
                          <span key={key} style={{ width: '45%', margin: '10px' }}>
                            <a key={key} target="_blank" href={caseinfo.images[key]}>
                              <div style={{ position: "relative" }}>
                                <img
                                  src={caseinfo.images[key]}
                                  style={{ height: "250px", width: "420px" }}
                                  alt="Other Images"
                                />
                                <img
                                  src="/assets/Adsum Advisory Services Pvt. Ltd._files/sidelogo.png"
                                  className="small-logo"
                                  width="75px"
                                  height="53px"
                                  alt="Side Logo"
                                />
                              </div>
                            </a>
                          </span>
                        );
                      }
                      return null;
                    })
                  }
                </div>
                <div className="row">
                  <div className="col-sm-12 newReport">
                    <div
                      style={{
                        padding: "15px 10px",
                        border: "1px solid #686868",
                      }}
                    >
                      Disclaimer:
                      <br />
                      o This report is issued without prejudice
                      <br />
                      o The valuation has been done based on the superficial
                      examination of vehicle condition and the doeuments
                      provided at the time of inspection.
                      <br />
                      o This report is valid for the date of inspection and time
                      only
                      <br />
                      o This valuation has been performed to the best of our
                      ability and our responsibility is limited to proven
                      negligence.
                      <br />
                      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', paddingRight: '20px' }}>
                        <img
                          src="/assets/stamp.jpeg"
                          width="80px"
                          height="80px"
                        />
                      </div>
                      <div className="text-right">
                        <p>
                          Authorized Signatory
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: 3 }}>&nbsp;</div>
                <div className="row">
                  <div className="col-sm-12 newReport">
                    <div
                      style={{
                        padding: 2,
                        border: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      {/* page footer */}
                      <div className="col-sm-12 newReport text-center">
                        <strong>Head Office:</strong>
                        <br />
                        Door No. 411,4th Floor, Eden Amsri Square,beside Apollo
                        Hospital, Sangeet Circle,
                        <br />
                        Secunderabad 500003, Telengana
                      </div>
                    </div>
                  </div>
                  {/* page footer ends */}
                </div>
              </div>
            </div>
            <br />
            {/* /div> <!-- A4 size */}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ThreeWheelerReport;
